<template>
  <div>
    <van-field
      readonly
      clickable
      required
      label="我想反馈"
      :value="value"
      placeholder="请选择"
      @click="showPicker = true"
    />
    <van-field
      v-model="form.contact_mobile"
      required
      type="digit"
      maxlength="11"
      label="手机号"
      placeholder="请输入手机号"
      error-message=""
    />
    <van-field
      required
      v-model="form.message"
      rows="2"
      autosize
      label="反馈"
      type="textarea"
      placeholder="请输入反馈"
      show-word-limit
      maxlength="150"
    />

    <div class="upload_suggest">
      <van-uploader
        v-model="image_list"
        multiple
        :after-read="afterRead"
        max-count="8"
        :before-delete="deleteImg"
      />
    </div>

    <van-button
      type="primary"
      block
      style="width: 90%; margin: 20px auto 0"
      @click="save"
      >提交反馈</van-button
    >

    <van-popup v-model="showPicker" round position="bottom">
      <van-picker
        value-key="name"
        show-toolbar
        :columns="columns"
        @cancel="showPicker = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { Picker, Field, Popup, Uploader, Button, Toast } from "vant";

import { post, form } from "@get/http";
export default {
  components: {
    vanPicker: Picker,
    vanField: Field,
    vanPopup: Popup,
    vanUploader: Uploader,
    vanButton: Button,
  },
  data() {
    return {
      value: "",
      image_list: [],
      form: {
        type: "",
        message: "",
        contact_mobile: "",
        image_list: [],
      },
      showPicker: false,
      columns: [],
    };
  },
  created() {
    this.getTypeList();
  },
  methods: {
    async save() {
      const f = this.form;
      if (!f.type) {
        Toast.fail("请选择分类");
        return false;
      }
      if (!f.message) {
        Toast.fail("请填写反馈内容");
        return false;
      }
      if (!f.contact_mobile || f.contact_mobile.length < 11) {
        Toast.fail("请填写手机号码");
        return false;
      }
      await post("/MemberFeedbacks/create", {
        data: {
          ...this.form,
        },
      });
      Toast.success("提交成功");
      this.$router.push({
        name: "New_Me",
      });
    },

    async afterRead({ file }) {
      Toast.loading({
        message: "加载中...",
        forbidClick: true,
      });
      var formData = new window.FormData();
      formData.append("file", file);

      let result = await form("/Upload/upload_file", { data: formData });
      this.form.image_list.push(result);
      Toast.success("上传成功");
    },
    async getTypeList() {
      let result = await post("/FeedbackType/index");
      this.columns = result;
    },
    onConfirm(value) {
      this.value = value.name;
      this.form.type = value.id;
      this.showPicker = false;
    },
    deleteImg(e, ee) {
      this.image_list.splice(ee.index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload_suggest {
  background: #fff;
  padding: 0.6rem;
}
</style>
